import axios from 'axios';


const baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosService = axios.create({
	baseURL: baseURL + "/api",
	responseType: "json",
	withCredentials: true,
	/*
		httpAgent: new https.Agent({
			rejectUnauthorized: false
		}),
	*/
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
	},
});

axiosService.interceptors.response.use(null, async (error) => {

	if (error.response.status === 401) {
		window.location.replace("/login");
	}

	return Promise.reject(error);

});


export default axiosService;
