import logo from "../../../assets/LSDA-scaled.jpg";
import Select from "@material-ui/core/Select";
import {IconButton, MenuItem} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import {useLocation, withRouter} from "react-router-dom";
import {getTemporadesService} from "../../../data/services/novaTemporada/novaTemporadaService";
import {setTemporadaTreballApi} from "../../../data/api/temporades/temporadesApi";
import {getFromStorage, saveToStorage} from "../../../utils/utils";
import {CURRENT_WORKING_TEMPORADA_ID} from "../../../utils/constants";

const Header = ({
					userName = "",
					history,
				}) => {

	const location = useLocation();
	const currentTemporadaId = getFromStorage(CURRENT_WORKING_TEMPORADA_ID);
	const [temporada, setTemporada] = useState(currentTemporadaId ? currentTemporadaId : 3);

	const [seasons, setSeasons] = useState([{id: 2, any: 2020, name: "Temporada 2020", active: false}, {
		id: 3,
		any: 2021,
		name: "Temporada 2021",
		active: true
	}])

	const [configMenu, setConfigMenu] = React.useState(null);
	const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);

	useEffect(() => {
		getTemporadesService()
			.then(res => {
				setSeasons(res.temporades)
				let activeTemporada = res.temporades.find(item => item.active === true);
				setTemporada(activeTemporada.id)
				saveToStorage(CURRENT_WORKING_TEMPORADA_ID, activeTemporada.id)
			})
			.catch(err => {
				console.log("Error fetching temporades", err)
			})
	}, [])

	const handleClickUserMenu = (event) => {
		setAnchorUserMenu(event.currentTarget);
	};

	const handleUserMenu_EditProfile = () => {
		setAnchorUserMenu(null);
		history.push("/editprofile");
	};

	const handleUserMenu_LogOut = () => {
		setAnchorUserMenu(null);
		history.push("/logout");
	};

	const [anchorSettingsMenu, setAnchorSettingsMenu] = React.useState(null);

	const handleClickSettingsMenu = (event) => {
		setAnchorSettingsMenu(event.currentTarget);
	};
	const handleSettingsMenu_Users = () => {
		setAnchorSettingsMenu(null);
		history.push("/usuaris");
	};

	const handleTemporadaChange = (e) => {
		if (!e.target.value) return;
		let res = window.confirm("Es canviarà la temporada de treball. Si has deixat canvis pendents de desar, es perdran. Segur que vols continuar?");
		if (!res) return;
		setTemporadaTreballApi(e.target.value)
			.then(r => {
				setTemporada(e.target.value);
				window.location.reload();
			})
			.catch(e => {
				console.error("[HeaderHandleTemporadaChange]", e)
				alert("Error canviant temporada de treball")
			})
	};

	const handleAddSeason = (e) => {
		e.stopPropagation();
		history.push('/nova-temporada')
	};


	const goToEditTemporades = () => {
		history.push("/editar-temporada/dades-temporada");
	};

	const goToFasesRegulars = () => {
		history.push("/fases-regulars");
	};

	const goToConcerts = () => {
		history.push("/concerts");
	};

	const goToUsuaris = () => {
		history.push("/usuaris");
	};

	const goToLogOut = () => {
		history.push("/logout");
	};

	const isCurrent = (string) => {
		return location.pathname.includes(string);
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				backgroundColor: "white",
				paddingLeft: 32,
				paddingRight: 32,
				paddingTop: 16,
				paddingBottom: 16,
			}}
		>
			<img
				src={logo}
				style={{height: 46, width: 46, objectFit: "contain"}}
			/>

			<Select
				style={{marginLeft: 20, minWidth: 170}}
				variant={"outlined"}
				id="select-temporada"
				value={temporada}
				onChange={handleTemporadaChange}
			>
				{seasons.map((season) => (
					<MenuItem key={season.id} value={season.id}>{season.name}</MenuItem>
				))}
				<MenuItem onClick={handleAddSeason}>Afegir Temporada</MenuItem>
			</Select>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					alignItems: "center",
					width: "100%",
					marginLeft: 20,
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "90%",
					}}
				>
					<Button onClick={goToEditTemporades} color={isCurrent("editar-temporada") ? "secondary" : "default"}>
						Editar Temporada
					</Button>
					<Button color={isCurrent("fases-regulars") ? "secondary" : "default"} onClick={goToFasesRegulars}>Fases Regulars</Button>
					<Button color={isCurrent("concerts") ? "secondary" : "default"} onClick={goToConcerts}>Concert final</Button>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<IconButton
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClickSettingsMenu}
					>
						<SettingsIcon/>
					</IconButton>
					<Menu
						id="settings-menu"
						anchorEl={anchorSettingsMenu}
						keepMounted
						open={Boolean(anchorSettingsMenu)}
						onClose={() => setAnchorSettingsMenu(null)}
					>
						<MenuItem onClick={handleSettingsMenu_Users}>
							Usuaris
						</MenuItem>
					</Menu>

					<Button
						type={"button"}
						onClick={handleClickUserMenu}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							backgroundColor: "transparent",
							border: "none",
							cursor: "pointer",
						}}
					>
						<AccountCircle fontSize={"large"}/>
						<span style={{marginLeft: 10}}>{userName}</span>
					</Button>
					<Menu
						id="user-menu"
						anchorEl={anchorUserMenu}
						keepMounted
						open={Boolean(anchorUserMenu)}
						onClose={() => setAnchorUserMenu(null)}
					>
						{/*<MenuItem onClick={handleUserMenu_EditProfile}>
							Editar perfil
						</MenuItem>*/}
						<MenuItem onClick={handleUserMenu_LogOut}>
							Tancar sessió
						</MenuItem>
					</Menu>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Header);
