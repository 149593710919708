import React, {useEffect, useState} from "react";
import Body from "../../components/Layout/Body/Body";
import Button from "@material-ui/core/Button";
import {faseRegularDetailColumns} from "./faseRegularDetailTableContent";
import {cleanRow} from "../../utils/utils";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import Chip from '@material-ui/core/Chip';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {Box, Snackbar, TextField} from "@material-ui/core";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import {
	getFasesRegularsResultsService,
	updateFasesRegularsResultsService
} from "../../data/services/FasesRegulars/FasesRegularsService";
import PDFViewer from "../../components/unit/PDFViewer/PDFViewer";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import {publishResultatsFaseRegularApi} from "../../data/api/FasesRegulars/FasesRegularsApi";

const FaseRegularDetailScreen = ({history}) => {

	const location = useLocation();
	let {idElim} = useParams();

	const [isLoading, setIsLoading] = useState(false);

	const [faseRegular, setFaseRegular] = useState(null);

	const [initialDate, setInitialDate] = useState('');
	const [finalDate, setFinalDate] = useState('');
	const validVotes = 300;
	const [totalVots, setTotalVots] = useState("");

	const [votsBlanc, setVotsBlanc] = useState("");
	const [votsNuls, setVotsNuls] = useState("");
	const [txtAfegeixAResultat, setTxtAfegeixAResultat] = useState("");
	const [txtSubstitueixResultat, setTxtSubstitueixResultat] = useState("");
	const [hasChanged, setHasChanged] = useState(false);
	const [rows, setRows] = useState([]);
	const [published, setPublished] = useState(false);
	const [pdfGenerat, setPdfGenerat] = useState("");

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");

	useEffect(() => {
		console.log(location.state);
		setInitialDate(location.state.dataInici);
		setFinalDate(location.state.dataFinal);
		fetchData();
	}, [location])

	const fetchData = () => {
		setIsLoading(true);
		getFasesRegularsResultsService(idElim)
			.then(r => {
				console.log(r);
				setFaseRegular(r.faseRegular)
				setRows(r.rows);
				setVotsBlanc(r.votsEnBlanc);
				setVotsNuls(r.votsNuls)
				setTotalVots(r.totalVots)
				setPublished(r.publicat)
				setPdfGenerat(r.pdfGeneratUrl)
				setTxtSubstitueixResultat(r.txtSubstitueixResultat)
				setTxtAfegeixAResultat(r.txtAfegeixAResultat)
				setIsLoading(false);
			})
			.catch(e => {
				console.error("[SardanesScreen] error fetching data", e);
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_GET_DATA)
				setIsLoading(false);
			})
	}

	const handleGoBack = () => {
		history.goBack();
	};


	const handlePublish = () => {
		let res = window.confirm("Segur que vols publicar els resultats? S'enviarà el PDF generat a totes les emissores i usuaris.")
		if (res) {
			setIsLoading(true);
			publishResultatsFaseRegularApi(idElim)
				.then(r => {
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS);
					setPublished(r.data.res);
					setIsLoading(false);
				})
				.catch(e => {
					console.error("[SardanesScreen] error publishing results", e);
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
					setIsLoading(false);
				})

		}
	};

	const handleVotsBlanc = (event) => {
		setVotsBlanc(event.target.value);
	};

	const handleVotsNuls = (event) => {
		setVotsNuls(event.target.value);
	}

	const Chips = () => {
		if (published) {
			return <Chip label={'Publicat'} color={'success.main'}/>
		} else {
			return <Chip label={'Pendent de publicar'} color={'error.main'}/>
		}
	};

	const formatDate = (date) => {
		return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
	}

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[SardanesScreen] edited rows",editedRows)
	}

	const saveTable = () => {
		setIsLoading(true)
		const data = {
			...faseRegular,
			sardanes: rows,
			votsEnBlanc: votsBlanc,
			txtAfegeixAResultat,
			txtSubstitueixResultat,
			votsNuls,
		}
		updateFasesRegularsResultsService(data, idElim)
			.then(res => {
				setPdfGenerat("");
				showSetupSnackBar(SNACKBAR_TYPES.SUCCESS);
				fetchData()

			})
			.catch(err => {
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				console.error("[SardanesScreen] error sending rows", err)
				setIsLoading(false)
			})
		setHasChanged(false)
	};

	const HeadButtons = () => {
		return (
			<div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
				<Button onClick={handleGoBack} variant={"outlined"} color={'primary'}>
					<ArrowBackIosIcon/>
					Enrere
				</Button>
				<div>
					{published ?
						<Button onClick={() => window.open(pdfGenerat, "_blank")} variant={"outlined"} color={'primary'}>
						<div style={{display: 'flex', alignItems: 'center', margin: 4, justifyContent: 'space-between'}}>
							<PictureAsPdfIcon/> Descarregar PDF</div></Button>
						: <Button onClick={handlePublish} variant={"outlined"} color={'primary'}>Publicar resultats</Button>
							}

					<Button variant={"contained"} color={'primary'} style={{marginLeft: 12}} onClick={saveTable}>
						Desar
					</Button>
				</div>

			</div>
		)
	}

	return (
		<Body>
			<HeadButtons/>
			<div style={{display: 'flex', alignItems: 'center'}}>
				<h1 style={{marginRight: 20}}>Resultats eliminatoria {formatDate(initialDate)} a {formatDate(finalDate)}</h1>
				<Chips/>
			</div>
			{pdfGenerat && <PDFViewer file={pdfGenerat}/>}
			<EditableTable
				rows={rows}
				columns={faseRegularDetailColumns}
				editable={true}
				deletable={true}
				insertable={false}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				addNewRow={cleanRow}
				isLoading={isLoading}
			/>

			<Box
				component="form"
				noValidate
				autoComplete="off"
				style={{marginTop: 20}}
			>
				<TextField id="vots_blanc" label="Vots en blanc" variant="outlined"
						   value={votsBlanc} onChange={handleVotsBlanc}
						   style={{marginRight: 40}} type={"number"}/>
				<TextField id="vots_nuls" label="Vots nuls" variant="outlined" type={"number"}
						   style={{marginRight: 40}} value={votsNuls} onChange={handleVotsNuls}/>

			</Box>
			<Box
				component="form"
				noValidate
				autoComplete="off"
				style={{marginTop: 20}}
			>
				<TextField
					id="outlined-multiline-static"
					label="Text a afegir al resultat"
					value={txtAfegeixAResultat}
					multiline
					variant="outlined"
					onChange={(e) => {
						setTxtAfegeixAResultat(e.target.value)
					}}
					rows={8}
					style={{marginRight: 40, width: 'calc(50% - 40px)'}}
				/>

				<TextField
					id="outlined-multiline-static"
					label="Text a substituir al resultat"
					value={txtSubstitueixResultat}
					multiline
					variant="outlined"
					rows={8}
					onChange={(e) => {
						setTxtSubstitueixResultat(e.target.value)
					}}
					style={{width: '50%'}}
				/>
			</Box>
			<h3>Total de punts: {parseInt(totalVots)}</h3>
			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>

			<p>Notes informatives:</p>
			<ul>
				<li>En fer clic a &ldquo;Desar&rdquo; es generar&agrave; un document de forma autom&agrave;tica i es
					mostrarà a sobre la taula. Comproveu que el document sigui correcte.
				</li>
				<li>En fer clic a &ldquo;Publicar resultats&rdquo;, s&rsquo;enviar&agrave; el document als correus
					electr&ograve;nics registrats a &ldquo;Llistat d&rsquo;emissores&rdquo; i &ldquo;Llistat de
					col·laboradors&rdquo;.
				</li>
				<li>El camp &ldquo;Text a afegir als resultats&rdquo; indica que l&rsquo;eliminat&ograve;ria es considera v&agrave;lida per&ograve; es vol fer algun aclariment. Aquest text tamb&eacute; constar&agrave; al document generat autom&agrave;ticament.</li>
				<li>El camp &ldquo;Text a substituir al resultat&rdquo; indica que l&rsquo;eliminat&ograve;ria no es tindr&agrave; en compte i el text que es posi en aquest camp substituir&agrave; el resultat.</li>
			</ul>
		</Body>
	)
};

export default FaseRegularDetailScreen;
